window.$ = window.jQuery = window.JQuery = require('jquery');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['x-apikey'] = '123456';

let langPrefix = $('html').attr('lang') === 'uk' ? '/uk' : '';
let token = $('meta[name="csrf-token"]').attr('content');


import language from "./modules/lang";

function getFormData($form) {
    var unindexed_array = $form.serializeArray();
    var indexed_array = {};

    $.map(unindexed_array, function (n, i) {
        indexed_array[n['name']] = n['value'];
    });

    return indexed_array;
}

$(document).on('submit', '.js-ajax_form', function(e){
    e.preventDefault();   
    let action = '#'+ $(this).attr('id');
    $.ajax({
        url: $(action).attr('action'),
        type: "POST",
        data: getFormData($('#'+ $(this).attr('id'))),
        headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            "X-CSRF-TOKEN": token
        },
        beforeSend: function(){
            $('.preloader_container').css('display', 'flex');
        },
        success: function (data) {
            $('.preloader_container').css('display', 'none');
            if (data.success) {
                $(action).find('input[type="text"]').val('');
                switch(action){
                    case '#enterCallMe':
                        $('#modal_call_me .modal-body').addClass('d-none');
                        $('#modal_call_me .modal-subject').removeClass('d-none');
                        break;
                    case '#enterFeedbackForm':
                        $(action).find('textarea').val('');
                        $('button[data-target="#modal_feedback_answer"]').click();
                        break;
                }
            }
        },
        error: function (msg) {
            $('.preloader_container').css('display', 'none');
            console.log(msg);
            Object.entries(msg.responseJSON.data).forEach(([key, value]) => {
                $(action + ' .input_label[for="'+ key +'"]').addClass('error').append('<span>'+ value +'</span>');
            });
        }
    });
})